.footer__up {
  padding: 9rem 2rem;
  background: #191514;
}

.footer__title {
  color: #ffffff;
}

.footer__link {
  color: #999999;
}

.footer__bottom {
  background: #0b0505;
  color: #999999;
}
