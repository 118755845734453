.footer__up {
  background: #191514;
  padding: 9rem 2rem;
}

.footer__title {
  color: #fff;
}

.footer__link {
  color: #999;
}

.footer__bottom {
  color: #999;
  background: #0b0505;
}

/*# sourceMappingURL=footer.239f004a.css.map */
